import {createContext, RefObject} from 'react'
import {v4 as uuidv4} from 'uuid'

export type ExternalViewerKey = string
export interface IMessageHandlerContext {
  registerViewer: (key: string, iframe: React.RefObject<HTMLIFrameElement>) => void
  unregisterViewer: (key: string) => void
  sendMessage: (iframe: RefObject<HTMLIFrameElement>, response: HandlerResponse) => void
  // This is just a way to force the implementation of a method to handle the events, but right
  // now it depends on the event listener to call it.
  handleAction: (e: MessageEvent) => void
  requestMode: (key: string, mode: ViewerMode) => void
}

// export type HandlerAction = keyof HandlerActionTable

export type HandlerAction = 'openViewer' | 'closeViewer' | 'requestViewer'

export type ViewerProps = {
  id: ExternalViewerKey
  viewerURL: string
  viewerParams: ViewerParams
  mode: ViewerMode
}

export enum ViewerMode {
  READ = 'READ',
  WRITE = 'WRITE',
}

export type ViewerParams = {
  exportFlags?: ExportOption[]
}

// export type ExportOption = 'png' | 'pdf'

export enum ExportOption {
  PDF = 'PDF',
  PNG = 'PNG',
}

export type ExternalViewerConfig = ViewerProps & {
  apiUrl: string
  name: string
  writable: boolean
}

export interface HandlerActionTable {
  // This no longer returns a response, but maybe we should enforce it
  getApiUrl: (viewerConfig: MessageEvent) => void
  openViewer: (viewerConfig: MessageEvent) => void
  closeViewer: (viewerConfig: MessageEvent) => void
  requestViewer: (viewerConfig: MessageEvent) => void
}

export const MessageHandlerContext = createContext<IMessageHandlerContext>({
  registerViewer: (key: string, iframe: RefObject<HTMLIFrameElement>) => {},
  unregisterViewer: (key: string) => {},
  sendMessage: (iframe: RefObject<HTMLIFrameElement>, response: HandlerResponse) => {},

  handleAction: (e: MessageEvent) => {},
  requestMode: (key: string, mode: ViewerMode) => {},
})

/*
    As mentioned in echarts viewers comments, the requests/responses ideally should
    have an unique key, to be able to match them to a proper callback, rather than passing
    the actiontype, for now (as a proof of concepto with only one action) we use this.
*/
export type HandlerResponse = {
  value: any
  reqId: string
  action: string
}

function createGenericViewerMessage(
  action: HandlerAction,
  config: ExternalViewerConfig
): HandlerResponse {
  return {
    action: action,
    value: config,
    reqId: uuidv4(),
  }
}

export function createRequestViewerMessage(config: ExternalViewerConfig): HandlerResponse {
  return createGenericViewerMessage('requestViewer', config)
}

export function createOpenViewerMessage(config: ExternalViewerConfig): HandlerResponse {
  return createGenericViewerMessage('openViewer', config)
}

export function createCloseViewerMessage(config: ExternalViewerConfig): HandlerResponse {
  return createGenericViewerMessage('closeViewer', config)
}
