import {toastDefaultConfig} from '@/_omicsbox/toastConfig'
import {S3ProviderListOutput, StorageGetOutput} from '@aws-amplify/storage'
import {API, Storage} from 'aws-amplify'
import {Id, toast} from 'react-toastify'
import {
  Associate2PropsResponse,
  dbItem,
  FsProps,
  HistoryProps,
  Job,
  JobHistory,
  RenameProps,
  shareOutput,
  unshareOutput,
  UsageProps,
} from '../../../../_omicsbox/helpers'
import {getLogger} from '../../../../_omicsbox/helpers/logUtils'

const apiName: string = process.env.REACT_APP_API_NAME || 'JobService'
interface QueryProps {
  limit?: number
  lastKey?: string
  filePrefix?: string
}

const getFiles = async (identityId: string): Promise<S3ProviderListOutput> => {
  if (!identityId) {
    throw new Error('identityId not provided')
  }
  const fileListOutput: S3ProviderListOutput = await Storage.list(``, {
    customPrefix: {public: '', private: '', protected: ''},
    level: 'private',
    identityId: identityId,
  })

  return fileListOutput
}

const getFilesV2 = async (
  filePrefix?: string,
  lastKey?: string,
  limit?: number
): Promise<dbItem[] | undefined> => {
  let files: dbItem[] | undefined = undefined
  const queryParams: QueryProps = {limit: limit, lastKey: lastKey, filePrefix: filePrefix}
  const getFilesResponse = await API.get(apiName, 'storage/files', {
    queryStringParameters: queryParams,
  })
  files = getFilesResponse.files.items
  let lastKeyResponse = getFilesResponse.files.lastKey
  while (lastKeyResponse) {
    const response = await API.get(apiName, 'storage/files', {
      queryStringParameters: {...queryParams, lastKey: lastKeyResponse},
    })
    //add new results to files
    files = files ? files.concat(response.files.items) : response.files.items
    //update lastKeyResponse for next request
    lastKeyResponse = response.files.lastKey
  }

  return files
}

const downloadFile = async (fileName: string, isMultiple: boolean): Promise<string | Blob> => {
  if (!fileName) {
    throw new Error('fileName not provided')
  }

  let t: Id | undefined = undefined
  let file: StorageGetOutput<any> | string | undefined = undefined
  try {
    file = await Storage.get(fileName, {
      customPrefix: {public: '', private: '', protected: ''},
      level: 'private',
      download: isMultiple,
      progressCallback: (p_1) => {
        const progress = p_1.loaded / p_1.total
        if (t === undefined) {
          t = toast(`Downloading ${fileName.substring(0, 50) + '...'}`, {
            ...toastDefaultConfig,
            hideProgressBar: false,
            progress,
          })
        } else {
          if (toast.isActive(t)) {
            toast.update(t, {
              progress,
            })
          }
        }
      },
    })
  } catch (error) {
    getLogger().error(error)
  }
  if (t) {
    toast.done(t)
    toast.dismiss(t)
  }
  //@ts-ignore
  const response: string | Blob = typeof file === 'string' ? file : file.Body
  return response
}

const removeFile = (fileName: string) => {
  if (!fileName) {
    throw new Error('fileName not provided')
  }

  return new Promise<string>((resolve, reject) => {
    try {
      Storage.remove(fileName, {
        customPrefix: {public: '', private: '', protected: ''},
        level: 'private',
      })
      resolve(fileName)
    } catch (error) {
      reject(error)
    }
  })
}

const uploadFile = async (filePath: string, fileToUpload?: File) => {
  if (!filePath) {
    throw new Error('filePath not provided')
  }
  const filename = filePath.split('/').pop() || ''
  let t: Id | undefined = undefined
  const res = await Storage.put(fileToUpload ? filePath : `${filePath}/`, fileToUpload || '', {
    level: 'private',
    customPrefix: {public: '', private: '', protected: ''},
    contentType: fileToUpload?.type || '',
    progressCallback: (p_1) => {
      const progress = p_1.loaded / p_1.total
      if (t === undefined) {
        t = toast(`Uploading ${filename.substring(0, 50) + '...'}`, {
          ...toastDefaultConfig,
          hideProgressBar: false,
          progress,
        })
      } else {
        toast.update(t, {
          progress,
        })
      }
    },
  })
  if (t) {
    toast.done(t)
  }
  return res
}

const shareFiles = async (fileNames: string[], identityId: string) => {
  if (!identityId) {
    getLogger().error('identityId not provided')
    console.warn('identityId not provided')
  }
  const s3Objects = fileNames.map((fileName) => identityId + '/' + fileName)
  let response: shareOutput = {publicUrls: []}
  try {
    response = await API.post(apiName, 'storage/shareLink', {body: {s3Objects: s3Objects}})
  } catch (error: any) {
    getLogger().error('shareOutput', error.message)
  }

  return response
}
const unshareFiles = (paths: string[], identityId: string) => {
  let response: Promise<unshareOutput> = Promise.resolve({objectsUnshared: []})
  if (!identityId) {
    getLogger().error('identityId not provided')
    console.warn('identityId not provided')
  }
  const s3Objects = paths.map((path) => identityId + '/' + path)

  response = API.post(apiName, 'storage/unshare', {body: {s3Objects}})
  return response
}

const getHistory = async (props: HistoryProps): Promise<Job[]> => {
  let response: JobHistory = {}
  response = await API.post(apiName, 'session/history', {body: props})
  return response.items || []
}

const getUsage = async (cancel?: boolean) => {
  let response: UsageProps = {}
  if (apiName) {
    let promise = API.get(apiName, 'storage/usage', {})
    try {
      response = await promise
      if (cancel) API.cancel(promise, 'getUsage aborted')
    } catch (error: any) {
      getLogger().error(error.message)
    }
  }
  return response.totalSize
}

const getSubscriptionId = async () => {
  let response: Associate2PropsResponse = {}
  response = await API.get(apiName, 'associate2', {})

  return response.subscriptionId
}

const renameFile = (props: RenameProps, identityId: string) => {
  const source: string = identityId + '/' + props.source
  return API.post(apiName, 'storage/rename', {body: {source, destination: props.destination}})
}

const moveFiles = (props: FsProps, identityId: string) => {
  if (Array.isArray(props.source)) {
    const source = props.source.map((path) => {
      return identityId + '/' + path
    })
    return API.post(apiName, 'storage/move', {body: {source, destination: props.destination}})
  }
}

const copyFiles = (props: FsProps, identityId: string) => {
  const source = props.source.map((path) => {
    return identityId + '/' + path
  })
  return API.post(apiName, 'storage/copy', {body: {source, destination: props.destination}})
}

export {
  downloadFile,
  getFiles,
  getFilesV2,
  getHistory,
  getSubscriptionId,
  getUsage,
  removeFile,
  moveFiles,
  shareFiles,
  unshareFiles,
  uploadFile,
  copyFiles,
  renameFile,
}
