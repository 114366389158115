import {Auth} from 'aws-amplify'

const redirectSignIn = process.env.REACT_APP_REDIRECT_SIGNIN_URL
const redirectSignOut = process.env.REACT_APP_REDIRECT_SIGNOUT_URL
const bucket = process.env.REACT_APP_BUCKET
const userPoolId = process.env.REACT_APP_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
const oauthDomain = process.env.REACT_APP_OAUTH_DOMAIN

export const awsConfig = {
  Auth: {
    identityPoolId: identityPoolId,
    region: 'us-east-2',
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: oauthDomain,
      scope: ['email', 'profile', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
      redirectSignIn: redirectSignIn,
      redirectSignOut: redirectSignOut,
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: bucket,
      region: 'us-east-2',
    },
  },
  API: {
    endpoints: [
      {
        name: 'JobService',
        endpoint: apiEndpoint,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          }
        },
      },
    ],
  },
}
