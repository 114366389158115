import {getLogger} from '@/_omicsbox/helpers'
import {ChangeEvent, Component} from 'react'
import {createRequestViewerMessage, ExternalViewerConfig} from './handler-structures'

type BridgeState = {
  viewersConfigs: ExternalViewerConfig[]
  selectedOption: string
}

const REST_SERVER = process.env.REACT_APP_REST_FAKE_SERVER_URL

export class BridgeViewerManager extends Component<{}, BridgeState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      viewersConfigs: [],
      selectedOption: '',
    }
  }

  controller = new AbortController()

  componentDidMount() {
    fetch(`${REST_SERVER}/viewers`, {signal: this.controller.signal})
      .then((response) => {
        return response.json()
      })
      .then((jsonResponse) => {
        this.setState({viewersConfigs: jsonResponse, selectedOption: jsonResponse[0]?.id})
      })
      .catch((error) => getLogger().error(error))
  }

  componentWillUnmount(): void {
    this.controller.abort()
  }

  handlerSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    this.setState({
      selectedOption: e.target.value,
    })
  }

  handlerButton = (): void => {
    const selectedConfig = this.state.viewersConfigs.find(
      (config) => config.id.toString() === this.state.selectedOption.toString()
    )

    if (selectedConfig) {
      window.postMessage(createRequestViewerMessage(selectedConfig))
    }
  }

  render() {
    return (
      <div style={{marginBottom: '20px'}}>
        <h1>Select viewer to display</h1>
        <select onChange={this.handlerSelect}>
          {this.state.viewersConfigs.map((item) => (
            <option value={item.id} key={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <button onClick={this.handlerButton}>Add viewer</button>
      </div>
    )
  }
}
