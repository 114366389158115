import {
  ExportOption,
  ViewerMode,
} from '../modules/widgets/components/external-viewer/handler-structures'

const externalViewerConfig = {
  key: 'testkey',
  id: 'testId',
  viewerURL: 'https://viewers.biobam.com/fake',
  viewerParams: {
    exportFlags: [ExportOption.PNG],
  },
  mode: ViewerMode.READ,
  writable: false,
  apiUrl: 'https://api.biobam.com/fake_api/',
  name: 'Test config',
}

const viewersList = [
  {
    id: 1,
    viewerURL: '/echarts/index.html',
    viewerParams: {
      exportFlags: ['PDF', 'PNG'],
    },
    apiUrl: 'https://s3.eu-central-1.amazonaws.com/dev.resources.biobam.com/echarts_api/api/1',
    name: 'WebCharts Example 1',
    mode: 'READ',
  },
  {
    id: 2,
    viewerURL: '/echarts/index.html',
    viewerParams: {
      exportFlags: ['PNG'],
    },
    apiUrl: 'https://s3.eu-central-1.amazonaws.com/dev.resources.biobam.com/echarts_api/api/2',
    name: 'WebCharts Example 2',
    mode: 'READ',
  },
  {
    id: 3,
    viewerURL: '/echarts/index.html',
    viewerParams: {
      exportFlags: ['CSV'],
    },
    apiUrl: 'https://s3.eu-central-1.amazonaws.com/dev.resources.biobam.com/echarts_api/api/2',
    name: 'WebCharts Example 3 (locked)',
    mode: 'READ',
  },
  {
    id: 4,
    viewerURL: '/table-viewer/build/index.html',
    viewerParams: {
      exportFlags: ['CSV'],
    },
    apiUrl: 'https://s3.eu-central-1.amazonaws.com/dev.resources.biobam.com/echarts_api/api/2',
    name: 'Table Viewer example',
    mode: 'READ',
  },
  {
    id: 5,
    viewerURL: '/cwl-viewer/index.html',
    viewerParams: {
      exportFlags: [],
    },
    apiUrl: 'https://s3.eu-central-1.amazonaws.com/dev.resources.biobam.com/echarts_api/api/5',
    name: 'Workflow Example',
    mode: 'READ',
  },
  {
    id: 6,
    viewerURL: '/pathways-viewer/build/index.html',
    viewerParams: {
      exportFlags: [],
    },
    apiUrl: 'https://s3.eu-central-1.amazonaws.com/dev.resources.biobam.com/echarts_api/api/6',
    name: 'Pathways Example',
    mode: 'READ',
  },
]

export const dummyData = {
  externalViewerConfig,
  viewersList,
}
