import {rest} from 'msw'
import {dummyData} from '../app/tests/mockData'
import {dummyData as viewersMock} from '../app/tests/viewersMockData'
export const handlers = [
  rest.get('https://cloud.biobam.com/v2/storage/files', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        files: {items: dummyData.mockUseFetchData, count: dummyData.mockUseFetchData.length},
      })
    )
  }),
  rest.post('https://cloud.biobam.com/v2/storage/copy', (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({message: 'OK'}))
  }),
  rest.post('https://cloud.biobam.com/v2/storage/move', (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({message: 'OK'}))
  }),
  rest.post('https://cloud.biobam.com/v2/storage/shareLink', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        publicUrls: ['https://storage.biobam.com/shared/9ebd7f69-fd18-492d-ad19-98595a44e61e'],
      })
    )
  }),
  rest.post('https://cloud.biobam.com/v2/storage/unshare', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        objectsUnshared: [
          'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f/namaste.jpg',
          'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f/counts_mice.box',
        ],
      })
    )
  }),
  rest.get('http://localhost/media/icons/duotune/files/fil010.svg', async (_, res, ctx) => {
    const imgBuffer = await fetch('https://picsum.photos/200').then((res) => res.arrayBuffer())
    return res(
      ctx.set('Content-Length', imgBuffer.byteLength.toString()),
      ctx.set('Content-Type', 'image/svg+xml'),
      ctx.body(imgBuffer)
    )
  }),
  rest.get('http://localhost/media/icons/duotune/files/fil013.svg', async (_, res, ctx) => {
    const imgBuffer = await fetch('https://picsum.photos/200').then((res) => res.arrayBuffer())
    return res(
      ctx.set('Content-Length', imgBuffer.byteLength.toString()),
      ctx.set('Content-Type', 'image/svg+xml'),
      ctx.body(imgBuffer)
    )
  }),
  rest.get('http://localhost/media/icons/duotune/files/fil018.svg', async (_, res, ctx) => {
    const imgBuffer = await fetch('https://picsum.photos/200').then((res) => res.arrayBuffer())
    return res(
      ctx.set('Content-Length', imgBuffer.byteLength.toString()),
      ctx.set('Content-Type', 'image/svg+xml'),
      ctx.body(imgBuffer)
    )
  }),
  rest.get('http://localhost/download', async (req, res, ctx) => {
    const isSingle = req.url.searchParams.get('single')
    const imgBuffer = await fetch('https://picsum.photos/200').then((res) => res.arrayBuffer())
    var ab = new ArrayBuffer(imgBuffer.length)
    var view = new Uint8Array(ab)
    for (var i = 0; i < imgBuffer.length; ++i) {
      view[i] = imgBuffer[i]
    }
    if (isSingle === 'true') {
      return res(
        ctx.set('Content-Type', 'text/plain'),
        ctx.body(JSON.stringify({file: 'ob_web.png'}))
      )
    } else {
      return res(
        ctx.set('Content-Length', ab.byteLength.toString()),
        ctx.set('Content-Type', 'image/jpg'),
        ctx.body(ab)
      )
    }
  }),
  rest.delete(
    'https://omicscloudstoragemicscloudstoragefe9082e121bce1654e41.s3.us-east-2.amazonaws.com/us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/0tinyjoint.webp',
    (_, res, ctx) => {
      return res(ctx.status(204))
    }
  ),
  rest.options('https://cognito-idp.us-east-2.amazonaws.com/', (_, res, ctx) => {
    return res(ctx.status(200))
  }),
  rest.post('https://cognito-idp.us-east-2.amazonaws.com/', (req, res, ctx) => {
    if (req.headers.get('x-amz-target') === 'AWSCognitoIdentityProviderService.InitiateAuth') {
      return res(
        ctx.status(200),
        ctx.set('content-type', 'application/x-amz-json-1.1'),
        ctx.json({
          ChallengeName: 'PASSWORD_VERIFIER',
          ChallengeParameters: {
            SALT: '9dbc27d809c8ace4fbbb2590886cfbf8',
            SECRET_BLOCK:
              'jKGkoKD8XTaXYeqnYM7lX/0NmVjWHwUxVIuy7xdLcqf7RTYLYg44seBSe75eBMvvCaa0S1QxIzgcfjXml7Ke/k6xYYC48xJhKlg1kizwNcyAbM4tAIgUoCFZX1iSeVwnWdoB3U8wpPO/fM+9ltWCpD/MrgZ6DQJ3dVR6xB5CreOmpN1KoJsFAuhHoh+2UAdZH7Twdc2LaLYj5TttOqIhYNR1toZ7F2HG4re0++JEbIQZ1gL7Bw4S1tMwNxTgMIL95wzqliVXeF4y3/1ypCQ5hZ5h+9zNPp8I0ZikpIFMGxUmo5opLnkLvOXeFQnOccs+zxN9eDlkAdzM0+YYSIShE3zTyySGFq7hWy26ly7Evz07jqlbUhd1YNPYz5HworeY+O24at1WDl1XdNr9hyDLyl+HX3Uyi/9O+JLD3DAvtp2nLrX4K674noe8VNcjnVQSZR+MfxfNAog7AalIcS2h3B6Hmr6Akx5ICkiLQhKCnyDQZJ0Bip8lgU+qi/UrnT9iYadGT/WMaWb34u26CpFO4Klq+gHRwFBCVwd9flLB7RFeqpsA+ntM7eXZfvhSZbR6mJmFrNfmLH5hQZT5HPqF2ntaCKpkyaChQBhM2lYyVGN2e1KC+5x+K29f5867LmqnXac9wzdGiYk/2HiFmpCH0uLKAJcH6r1xSCrMa2bloSUZOuwIfJFaejX3OUmvVVmw9gSVYgpwSJrL7S8wFCXDuYw+Dan22tnH8HrhYt1bnXy0BoNFpBXct8CJIrnai1lrL/jOWcwS+7pM2PAXYeDwTk5Sd4D5oo+nm8Q7B2I6fm5V3K6H5Ayqp36bEQUAJhkPgYHZuA+vA2Askj/zKV62sllRfaabErvZ1jXMMIvQeF9Vdr58zPirKIXrnAt1o68ujZNWd8h9Y2AIj1lRyfp2MjU2G5EMOzYhqmdsFQNw+Qw9JaGv+4nfZv01wb9mQI9RRKGhVhMynIZ9Baj/Bc4ZrWT6gncPICkAlKlSpTpsabn/wnDs/NHxutZGWhFSdWVtbIePnQhcgreB9EGnAw4furo646UA3obii0RBguJ5tVteAfnW7TYgSaoTli1XCRLZN4omljlyBKkMY5w+NCyRgTiuAz5yQtv3f9u+YWxYVTHgbMdH38siW46Tz4wRiok+ol3IiDp+w43lKn8lIaMVt1aGGlfsp93IZnaq50cV82jtn/HAQ8YgQTzUbrgv+ablsgLrommcZ/50waWVNVQ9UEutBKc6Is0qyNvwYvuAw0EXyoiCoJCPaOasONz7CVxV0MqDeHVL8a+yOCZCAd/87odGX/7d3K98OuZ/R5p9M6VksYFIKv3oQntigEB7WEgS49Nx7LWCKDZlLdihqX5GyobgI14jMLrAySK8FvKd3F23qwtH/WfA0GDk0yaaiWoRUaHwBArw+TuTkmVYfUIGDyOgyxUsjE9sqrG1XD3eD8LalurAtP15GqL9lJOlkAx+3Dk1AqFqQy41gvpFO3fRoj12EFd4GPrMD8q9mDeMVxV4fjhJBKfhrTY0EZGDtUBgA0TYioNTyLpKUmcyF1z6mKbl9vdbhj1KxR8998gvpHPoDqDZZ56+A20OFXZiP98s/5A1aIQeddjWcErMk+v3aiYK3m1xTMgMib+CcTAfUkD7XnC3sxzWEDizY3Mqsi4I1jm9LKtFFeWOq2s3GIL47WKoMvEUT87PrBLTE7hw2Yfxgb5iUWP1qn9j',
            SRP_B:
              'bfdb9c4c0a8d15768d6f535a3ca7977663a92babb3472bfb2819ae441bff60c731ab2898d6caaa21b9bb8a07e27c5d0410d1664f446059a56114c4ad31bb31d287d9cbcdd3a104501bc91f5b6d17221da36d0418ee1617c94e254e10888dd7bf0a39d596fc39dace49cbd89308bf8ae506478d1fa0413444e4c7974b8dc88037b04d0ca9c0dc57994d3c556ed386dee75b2ef0c8ac2f053e8f6e9afa47aaf9699194ccf0c939e41bfcea014b2affb0c7ffac145be341d7da947d63f50506abab8cb1aec3d1183321cf8d5f08b30ceaa7784e879b8209682dfd7020131a7bffd02321f8028be29517f0f1a1715818e3e4e70b3d887126ed9c9622386dadd779a32f25201d0aa50eebac9004249be53af9df54325ef015e270d654c29a23dd7663f060ae2e179ae894740820e415c7cd851c3c93b175daf00e1a5a53caf3d44d60377de69be11c7feceb2c933b7a2c75c7a12cd62660d5aacc2281c2cd4cb1723d08261bf226a1bfbe7a243c9c3c7e082b05be57a6c8d1cf6a878835e286c99d2f',
            USERNAME: 'd03c8930-7190-4f88-aa7b-0ebead6aa16a',
            USER_ID_FOR_SRP: 'd03c8930-7190-4f88-aa7b-0ebead6aa16a',
          },
        })
      )
    } else if (
      req.headers.get('x-amz-target') === 'AWSCognitoIdentityProviderService.RespondToAuthChallenge'
    ) {
      return res(
        ctx.status(200),
        ctx.set('content-type', 'application/x-amz-json-1.1'),
        ctx.json({
          AuthenticationResult: {
            AccessToken:
              'eyJraWQiOiIzUkNNS1pQOFpJT0NPekM3TTdrS01UUGZ4RUcyTWRyUDd5ZUJ4VnZkbllnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkMDNjODkzMC03MTkwLTRmODgtYWE3Yi0wZWJlYWQ2YWExNmEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl9jNmVPcW02TGoiLCJjbGllbnRfaWQiOiI2ajJpanEzN3UwcmUxNzI5NTdqcnFhbmk3YSIsIm9yaWdpbl9qdGkiOiIwMjJiYmU0NS1hZWUzLTQ3ZTQtOGQ4Ny05MjdhZjNjMzUwMmYiLCJldmVudF9pZCI6IjNlODNjMTQwLTVhMmItNGUwNy1hYjE3LWZlNjc2MzI5ZDIxMSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NzQxMjIwMzQsImV4cCI6MTY3NDEyNTYzNCwiaWF0IjoxNjc0MTIyMDM0LCJqdGkiOiJkMjllMTA0ZC1hMTJhLTQ2ZDMtODNmYy1hOTY2MTRiNDQ1NWIiLCJ1c2VybmFtZSI6ImQwM2M4OTMwLTcxOTAtNGY4OC1hYTdiLTBlYmVhZDZhYTE2YSJ9.Gx4AbZhUiVeF-6-9XOFnj3yjFeqE_Ksm1JpVky1Y3VOUmGvWTphHCtmkkSveDVJxf1JDospgSM8Svn6kP41SPf8c5f5yAIdPZQDdkZ5ZvVWYELIcdQ_6Tu4nNiGqT9FdxOytoOHfJnsYvtzV2twYOKN9rNuOtc1z6_xO_z0pFMQU1WY0RNi_wo6hrs1lc42Hl9yF07hkxQdYtDbGeCDGzKZ-fbN1Xq_035UPyrJd9mJKIyL_AwcEs8EPx20fcgZ13n3hW1CCyBMANed6eJy_DS-fhCT5W-LYriVj_JDRWCX4jR-B8M0sk7jvBRonJQP7c5UuC2yWm3gby5K2ipoIgQ',
            ExpiresIn: 86400,
            IdToken:
              'eyJraWQiOiJHR2Y2S1ZEalBDZnp3a3JHdXFcL1paVjlNTyt4RmhLWmh4dnNUTXlFNEc2WT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkMDNjODkzMC03MTkwLTRmODgtYWE3Yi0wZWJlYWQ2YWExNmEiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfYzZlT3FtNkxqIiwiY29nbml0bzp1c2VybmFtZSI6ImQwM2M4OTMwLTcxOTAtNGY4OC1hYTdiLTBlYmVhZDZhYTE2YSIsImdpdmVuX25hbWUiOiJMb3JlbnpvIiwib3JpZ2luX2p0aSI6IjAyMmJiZTQ1LWFlZTMtNDdlNC04ZDg3LTkyN2FmM2MzNTAyZiIsImF1ZCI6IjZqMmlqcTM3dTByZTE3Mjk1N2pycWFuaTdhIiwiaWRlbnRpdGllcyI6W3sidXNlcklkIjoiMTAwMjgwMDkwNDcwMDIwODg5NjQyIiwicHJvdmlkZXJOYW1lIjoiR29vZ2xlIiwicHJvdmlkZXJUeXBlIjoiR29vZ2xlIiwiaXNzdWVyIjpudWxsLCJwcmltYXJ5IjoiZmFsc2UiLCJkYXRlQ3JlYXRlZCI6IjE2MzU4NjAxMzcwMTIifV0sImV2ZW50X2lkIjoiM2U4M2MxNDAtNWEyYi00ZTA3LWFiMTctZmU2NzYzMjlkMjExIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NzQxMjIwMzQsImN1c3RvbTppZGVudGl0eUlkIjoidXMtZWFzdC0yOjBkMjRlMTdmLTIzY2ItNDFhOS05ZjQ0LTZlM2VlODBhMTM0ZiIsImV4cCI6MTY3NDEyNTYzNCwiaWF0IjoxNjc0MTIyMDM0LCJmYW1pbHlfbmFtZSI6Ik1pcmV0IiwianRpIjoiZjJiMDEwNmEtODRkOC00OTllLTkwNDQtY2E5ZTA4YTdjMWVkIiwiZW1haWwiOiJsbWlyZXRAYmlvYmFtLmNvbSJ9.C3sC_ZtqtStO0oNXUflee-DwImaCLEUmBkyKMstyD76rfH3n30qVNHVVjLbB_bVWj36VjcEEOuSvO4SC1bz6jxKrfAKPPs_-u3ZM1pGK480qQVDHYQYaMwpjjWSQY480N0gnPCsAQOyH3vf6BMg7cMNAzFFZUAkQzafLOIZSfu0EvitAqoT1g2UChF15w0CqwXex4KRxXzrbUnauGDphSv2UU400ItNN2QvDmbizspxrgRm7lnY1cxV95c5HM8FlBh35tXZetKe_kYmQbfdoEyc0Gt-H0fG-bn99sieYYOsFPmMoIcw7lLgiVpbjs-G_E_TfzCIQTxMJIOMzFxKNbw',
            RefreshToken:
              'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.e7zfZN4yZt5sus57w-afHKr3IDc817hwiwocdEdzL1PdqwQFjB7bs0232_dILRahllA8fijtoVxSlyrsBA8WMlApJNmt1RtxFk_csRuWCLxyWg9P6s_WJfr0Md5Qc_zKk69rAHk--FVqUTCNpGaZi3Vl8Znr5Em_ncUkE8fbnN3nKBxHCqpy7po3Lg4qt_DsOwyiW7Dgr3m0xD5Rbvx_pY_W4Us8urYutFGruZeXh80uk3RVP67_yaN7hDlCLBV0y-2cWvNafuGHRVVrwQZM6dkTmqhCG5ljeRgu_s0r9SXLJZ_GaSO9BbTVYc6MsG6HTn69CHh0C7ytJxvl1j899w.kAuFmget-s4R2xIJ.kb3S6rMeyAVH8nHJzEqM62jPEm_95n65s4Arw8Sk037stSOJXs4uGQEGpEmL6O_2frDGqDYonGXnIM5SIfdl5fV-it67omxkw1c9E0FmodAxc8QTW-VrmS3idfjbpVHLlyxyCz_Rgxx37rL3tPoIf1sibJt6ghijff8zmcR5Bf1Qm5CNa2ekrQ_yxVPLMOMSPiG6IHowA5DpXzhR0D1a8LxrOGmqu_T9Nzj4rZNPaLyjb617b5MDHtOAeE-cXVch-uQTvklkvj9YpmDmKPBGJJrJRm5anqgteJdeygKrbySQRD48uqIAGM9SzgJAp0suWnheOS6cDiX6lFy-hACP4BrmlwYDDT_vixB4842HSe82nj3Qux9JJ7ZaluNo5UHzVBnzf8Wi-br7ku9_07X7RK3fK3-oyliDDnu1eBJFRzNeHWUf0IOhWniKwwl9lzuTV1hvrxqj_On-og3aIp6wokcrhy-xO-YkwgZqD382ORBXs0wmdVc9GjLvqLQtP3vAvq3UYgT1_G-GEz1kri0WO_Xx3JDNThu1n6c_-gNgM1Oranm0o4wjU_GiZcSPIK9BahQ7cJnT6khgzU6Dw9GqO7SF_f3lKF4j4TmaVwDfEWQzmsRQg-Vd23KFXI2zMvg4Qir6ogmRW_anMgpCTnQQfz7MQrRRkYfOtw1_9auJ0tS9LRvm8Z0sS5vq_iwBW-JGYXzUhx_DzvQr1pgwg02Dx_yuZLmpyEgCK7nMehW8lZKL4laZm5A2NXoUI8TKC7h_OyxUiggHsJAl9YhBO5cBQTKi8Bpt1WXbayFwNAA2ozmwOX4dk8HYPK63sCqZD59Vp8srZq-1uE6E8st41WlPNK3REkP0pCOZtVbhAcIqldrOL2Ye5o5SLbw-Rf5GJJLBsJJ9xbNga7d-u2RcRhLIuLBBGBUmvH4xbtXq5DJcTotDSDBXCECuiWpqVyhjqM1VxpG-MAXer7iELWnmODEkpjZ7SVIiOHJKdgcBtZUOdFN-GlBR0MAzLsE5q7pBE8-d_as7wfzI1HSGbGW7M1RSmzhURudnJN7TbYCWrwc9LEBeooZn66g0Dz-EJhkmb2VMux5bOwiYHChafyGqvl_48nZH61HMomfG7jJ77GQPLguN0k-lbEwQiQxVMgX_O9EggL4tEVMxA-6qMTNG00BsGmE_rCTslgN0Ih-EZjLa951N9QNjgSgyE-c4XhL7JG135T8XlEjBVyD2KIe51UX2aslBZYNWKdxZyZ_0joa0PSvNYMwMjoxKkeGJgmpeWAKYG55_UVfCeiswy9rZr99SgyLoDXv05JQQaB7h5x9AVBXIea-h9TNrjkEzzA.Pc__NW_oAv_TDluWDcmOpA',
            TokenType: 'Bearer',
          },
          ChallengeParameters: {},
        })
      )
    } else if (req.headers.get('x-amz-target') === 'AWSCognitoIdentityProviderService.GetUser') {
      return res(
        ctx.status(200),
        ctx.set('content-type', 'application/x-amz-json-1.1'),
        ctx.json({
          UserAttributes: [
            {Name: 'sub', Value: 'd03c8930-7190-4f88-aa7b-0ebead6aa16a'},
            {
              Name: 'identities',
              Value:
                '[{"userId":"100280090470020889642","providerName":"Google","providerType":"Google","issuer":null,"primary":false,"dateCreated":1635860137012}]',
            },
            {Name: 'email_verified', Value: 'true'},
            {Name: 'given_name', Value: 'Lorenzo'},
            {Name: 'custom:identityId', Value: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f'},
            {Name: 'family_name', Value: 'Miret'},
            {Name: 'email', Value: 'lmiret@biobam.com'},
          ],
          Username: 'd03c8930-7190-4f88-aa7b-0ebead6aa16a',
        })
      )
    }
  }),
  rest.options('https://cognito-identity.us-east-2.amazonaws.com/', (_, res, ctx) => {
    return res(ctx.status(200))
  }),
  rest.post('https://cognito-identity.us-east-2.amazonaws.com/', (req, res, ctx) => {
    if (req.headers.get('x-amz-target') === 'AWSCognitoIdentityService.GetId') {
      return res(
        ctx.status(200),
        ctx.json({IdentityId: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f'})
      )
    } else if (
      req.headers.get('x-amz-target') === 'AWSCognitoIdentityService.GetCredentialsForIdentity'
    ) {
      return res(
        ctx.status(200),
        ctx.json({
          Credentials: {
            AccessKeyId: 'ASIAZLLAMOKDSCGYQRO2',
            Expiration: 1.674125635e9,
            SecretKey: 'm8d/9xyRGhA41XimtRD/Cq6QTSoALr9r+JmpKoVQ',
            SessionToken:
              'IQoJb3JpZ2luX2VjEAoaCXVzLWVhc3QtMiJGMEQCIAPaKuHIAG0CQ1uKAEjsHnLdYN0ePPXhJNDBu6MqMFygAiAClVw1jZ1nXeE6nWRe+JA+0prdLeDBkW1su0PtNORtDirEBAhzEAIaDDY0MjgzNjYyNDAwNyIMzCnszNn9cwGrujBGKqEE9e2Nq4XGyH6SXjcrueHrgu5IT6JiNiiWHoOdhXlN6/Uj8XK/CB0NFP3AjzKhtTrmpmH7rJAGztRA/0RNGl9alQ+lIBp9g39dUSejovtq+R4/+jncdn3YV6cq7hW8vLFTkAV53CmIkGpaE+HtttQWWuXKEAbS0GZgLWDXVEpmxuR86MSFGSI/WV0C8gQd3rjZETKoWqdL8VK/MfhG17LYhbCxPNHhZGUeBjuJjzVIs3VJbl4A4zL1g8aQfLEeMAE04yJkQDTmmt290vbZqY/iLqpVCt3ZcIYYqrwXoCKlpIMxl7CwwOLZwzdJzHcAx7Ne9ZDi1JSGS84m9DOAwCDGUAy6DEt+QFtOHD8Tk4DP5SSTbswsqkzq6U8lhgEW63j1Xf9zf9jSIxgb1aHSdqjk1nNe/+ViV2ohbXmuFtrEh+cLziccrJ76mwNtIuMD3f9wnqRgNdEVxmvpc+38y+ywOOea3tLpX0vFaqH+qA0S3dpM9HVaJsLJxPjS8t0aOTBkUbUUCRvZ60/lacg1eSbHVQV/eRteSXKRI6fVAOucZEZL5d2gxBGdJiSGPExUHjtpPLRr7WEs30p4JASuDsrxwQmjDYRXS4hkDHF4Il3UgkRGRmCDX+YvlTrSwfnpoDAtT81/DXf62EPEl9NQNXiVqquNa4gnFZ57e3o3UaAIj8BW8oBsFz4Wv5v87xOLxSsKF4WwsLTF+A4aSJJ/MXdbbpAws6akngY6hgIsf6OBJSnnkOArp22p+0itZP05TZebKolw2rClcfMe+p9k8PsShWzLsAuruwNfo+NC4Mw5OFIv9Zf3dxDAzmV31oH3ZJCitTyHMegCU8STAYjXZxjldYLxgYDH6Q2y5NtOskfStT/QU/O5XByMTqZFIkG9hpMZUWobyxY/SCoJqVcosLBfpnEQfq4JDcip/iNhI6/2E92gz58bGRqWBlCehUnQsGR8lqweBzeuxIj0yFe/ZP9GGLYWvsjIb8/Q4i8TF6gU/uMRt8QPLaNY9SPBpAHlySKwNDUve205lkbBaxiNixc8fzzlKEtz5N7ul72vgRPxRAShER/ieFg465OFoXjlIp+s',
          },
          IdentityId: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
        })
      )
    }
  }),
  rest.options('https://cloud.biobam.com/v2/associate2', (_, res, ctx) => res(ctx.status(200))),
  rest.get('https://cloud.biobam.com/v2/associate2', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        username: 'd03c8930-7190-4f88-aa7b-0ebead6aa16a',
        subscriptionId: 'BOX-MIRELORE-B8DB',
        identityId: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
        email: 'lmiret@biobam.com',
      })
    )
  }),
  rest.get('https://cloud.biobam.com/v2/storage/usage', (_, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        totalSize: dummyData.mockSelectedFiles.reduce((acc, file) => acc + file.size, 0),
        fileCount: dummyData.mockUseFetchData.length,
        estimatedEOMCost: 0,
        userId: 'us-east-2:0d24e17f-23cb-41a9-9f44-6e3ee80a134f',
      })
    )
  ),
  rest.get(
    'https://omicscloudstoragemicscloudstoragefe9082e121bce1654e41.s3.us-east-2.amazonaws.com/us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/zxczxczxcv/GraphQLvsREST.png',
    (_, res, ctx) => {
      const response = dummyData.mockDownloadFile
      return res(ctx.status(200), ctx.json({...response}))
    }
  ),
  rest.get(
    'https://omicscloudstoragemicscloudstoragefe9082e121bce1654e41.s3.us-east-2.amazonaws.com/us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/0tinyjoint.webp',
    (_, res, ctx) => {
      const response = dummyData.mockDownloadFile
      return res(ctx.status(200), ctx.json({...response}))
    }
  ),
  rest.get('http://localhost:3002/viewers', (_, res, ctx) =>
    res(ctx.status(200), ctx.json(viewersMock.viewersList))
  ),
  rest.put(
    'https://omicscloudstoragemicscloudstoragefe9082e121bce1654e41.s3.us-east-2.amazonaws.com/us-east-2%3A0d24e17f-23cb-41a9-9f44-6e3ee80a134f/e2e-test.txt',
    (_, res, ctx) => res(ctx.status(200), ctx.json({key: 'e2e-test.txt'}))
  ),
  rest.post('https://cloud.biobam.com/v2/storage/rename', (_, res, ctx) =>
    res(ctx.status(200), ctx.json({message: 'OK'}))
  ),
]
