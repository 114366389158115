/* eslint-disable jsx-a11y/anchor-is-valid */
import {refreshToken} from '@/_omicsbox/helpers'
import {FC, useEffect} from 'react'
import {PageTitle} from '../../../_omicsbox/layout/core'
import FileTable from '../../modules/widgets/components/FileTable'
import TableHistoryAg from '../../modules/widgets/components/TableHistoryAg'

const DashboardPage: FC = () => {
  useEffect(() => {
    const refreshTokenInterval = setInterval(refreshToken, 1000 * 50 * 60)
    return () => {
      clearInterval(refreshTokenInterval)
    }
  }, [])

  return (
    <>
      <div className='app-content flex-column-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <FileTable title='Cloud Files' />
          <TableHistoryAg title='Job Monitor' />
        </div>
      </div>
      <div id='minimized_cards_holder'></div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
