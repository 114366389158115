import React, {ReactElement} from 'react'
import {ExportOption, MessageHandlerContext, ViewerMode, ViewerProps} from './handler-structures'
import styles from './style.module.css'

// PureComponent seems to avoid re-rendering
export class ExternalViewer extends React.PureComponent<ViewerProps, {}> implements ReactElement {
  type: string | React.JSXElementConstructor<any>
  key: string

  private iframeRef: React.RefObject<HTMLIFrameElement>

  static contextType = MessageHandlerContext

  context!: React.ContextType<typeof MessageHandlerContext>

  // Declare not supported by jest & react-scripts
  // declare context: React.ContextType<typeof MessageHandlerContext>

  constructor(props: ViewerProps) {
    super(props)

    this.type = 'ExternalViewer'
    this.key = props.id
    this.iframeRef = React.createRef()
  }

  componentDidMount() {
    this.context.registerViewer(this.key, this.iframeRef)
  }

  componentWillUnmount() {
    this.context.unregisterViewer(this.key)
  }

  exportHandler = (event: any) => {
    let exportType = event.target.dataset.type as ExportOption
    let filePath = prompt(`Output file name (${exportType}) ?`)

    if (filePath) {
      this.context.sendMessage(this.iframeRef, {
        reqId: this.props.id,
        value: filePath,
        action: `get${exportType}`,
      })
    }
  }

  requestWriteMode = (event: any) => {
    this.context.requestMode(this.key, ViewerMode.WRITE)
  }

  render() {
    // const genId = uuidv4()
    const buttons: JSX.Element[] = []

    // TODO: this needs to be moved OUTSIDE render function or else will create new buttons
    this.props.viewerParams.exportFlags?.forEach((flag) => {
      buttons.push(
        <button data-type={flag} onClick={this.exportHandler} key={flag} className={styles[flag]}>
          {flag}
        </button>
      )
    })

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {/*<span>Mode: {this.props.mode.toString()}({genId})</span>*/}
          <span>{buttons}</span>
        </div>
        {/*{this.props.mode === ViewerMode.READ && <div className={styles.readMode}>This viewer has been opened in read-only mode <button onClick={this.requestWriteMode}>Transfer write permissions to this instance</button></div>}*/}
        <iframe
          title='iframe-container'
          ref={this.iframeRef}
          className={styles.iframe}
          src={this.props.viewerURL}
          width='100%'
        ></iframe>
      </div>
    )
  }
}
